import {
  DependencyList,
  RefObject,
  useCallback,
  useEffect,
  useState
} from "react";

import { useScrollEventListener } from "../../scroll-utils";

export function useHiddenElem<T extends HTMLElement>(
  resultElem: RefObject<T>,
  deps: DependencyList = []
) {
  const [hiddenY, setHiddenY] = useState(0);
  const calcHiddenY = useCallback(() => {
    if (!resultElem.current) {
      return;
    }
    setHiddenY(
      resultElem.current.getBoundingClientRect().bottom - window.innerHeight
    );
  }, [setHiddenY, resultElem]);
  useScrollEventListener(calcHiddenY);
  useEffect(calcHiddenY, [...deps, calcHiddenY, resultElem]);
  return hiddenY;
}
