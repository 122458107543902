import { useMsal } from "@azure/msal-react";
import { FC } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { Logo } from "../../atoms/Logo";

import "./Header.scss";

export const Header: FC = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const location = useLocation();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className="Header"
      data-bs-theme="dark"
      bg="dark-green"
    >
      <Container>
        <Link to="/" className="navbar-brand p-0 rounded">
          <Logo />
        </Link>
        {/* <Navbar.Brand href="#home" className="p-0 rounded"></Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link
              to="/"
              className={[
                "nav-link",
                location.pathname === "/" ? "active" : "",
              ].join(" ")}
            >
              Home
            </Link>
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Link
              to="/about"
              className={[
                "nav-link",
                location.pathname === "/about" ? "active" : "",
              ].join(" ")}
            >
              About
            </Link>
            {/* <Nav.Link href="#link">Link</Nav.Link> */}
            <NavDropdown
              title={activeAccount ? (activeAccount.name as string) : "不明"}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  handleLogoutRedirect();
                }}
              >
                Log out
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
