import { CSSProperties, FC, ReactNode, useEffect, useState } from "react";

export const FadeInOut: FC<{
  fadeIn: boolean;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}> = ({ fadeIn, className, style, children }) => {
  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (fadeIn && first) {
      setFirst(false);
    }
  }, [first, fadeIn]);
  const classNames = ["animate__faster", "animate__animated"];
  if (fadeIn) {
    classNames.push("animate__fadeIn");
  } else {
    classNames.push("animate__fadeOut");
  }
  if (first && !fadeIn) {
    classNames.push("d-none");
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(" ")} style={style}>
      {children}
    </div>
  );
};
