import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import { TutorialContext } from "./TutorialContext";

type Sequence = Array<SequenceElement>;
type SequenceElement =
  | string
  | number
  | ((element: HTMLElement | null) => void | Promise<void>);

export const Slide: FC<{
  targetActiveIndex: number;
  initialDelay?: number;
  showButtonDelay?: number;
  src: string;
  alt: string;
  captionHeader: ReactNode | string;
  sequence: Sequence;
  captionBodyClassName?: string;
  nextButtonText?: string;
  onNext: () => void;
}> = ({
  targetActiveIndex,
  initialDelay = 1000,
  showButtonDelay = 5000,
  src,
  alt,
  captionHeader,
  sequence,
  captionBodyClassName = "text-start",
  nextButtonText = "次へ",
  onNext,
}) => {
  const [show, setShow] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const { activeIndex } = useContext(TutorialContext);

  useEffect(() => {
    if (targetActiveIndex !== activeIndex) {
      return;
    }
    setTimeout(() => {
      setShow(true);
    }, initialDelay);
    setTimeout(() => {
      setShowNextButton(true);
    }, showButtonDelay);
  }, [initialDelay, showButtonDelay, targetActiveIndex, activeIndex]);

  if (activeIndex < targetActiveIndex) {
    return null;
  }

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <img alt={alt} src={src} />
      </div>
      <Carousel.Caption className="text-light animate__animated animate__fadeIn">
        <h3>{captionHeader}</h3>
        {show && (
          <>
            <p className={["small", captionBodyClassName].join(" ")}>
              <TypeAnimation sequence={sequence} speed={60} />
            </p>
            <div className="d-flex justify-content-center gap-1 mb-1">
              {showNextButton && (
                <Button
                  size="sm"
                  variant="success"
                  className="animate__animated animate__fadeIn"
                  onClick={() => {
                    onNext();
                  }}
                >
                  {nextButtonText}
                </Button>
              )}
            </div>
          </>
        )}
      </Carousel.Caption>
    </>
  );
};
