import { FC } from "react";
import { TypeAnimation } from "react-type-animation";

import { Logo } from "../../atoms/Logo";

import "./Loading.scss";

export const Loading: FC = () => {
  return (
    <div className="Loading d-flex h-100 align-items-center">
      <div className="text-center w-100">
        <div>
          <Logo style={{ width: "15rem" }} />
        </div>
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed once, initially
            "now loading ...",
            4000,
            "just a moment ...",
            4000,
          ]}
          speed={40}
          style={{ fontSize: "1.5em" }}
          repeat={Infinity}
          className="text-secondary"
        />
      </div>
    </div>
  );
};
