import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { FC, ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { completedTutorial } from "../pages/Tutorial";

const RedirectLogin: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, [navigate]);

  return null;
};

export const Guard: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      location.pathname === "/logged-in" ||
      location.pathname === "/tutorial" ||
      completedTutorial()
    ) {
      return;
    }
    navigate("/tutorial");
  }, [location, navigate]);
  return (
    <>
      {/* ログイン時に表示 */}
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>

      {/* 未ログイン時に表示 */}
      <UnauthenticatedTemplate>
        <RedirectLogin />
      </UnauthenticatedTemplate>
    </>
  );
};
