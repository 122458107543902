import { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { LogoText } from "../../atoms/LogoText";
import { SigninedTemplate } from "../../templates/SigninedTemplate";

import "./About.scss";
import { useHttpClient } from "../../http-client";

const Version = process.env.REACT_APP_VERSION;

type VersionType = {
  version: string;
};

export const About: FC = () => {
  const httpClient = useHttpClient();
  const [apiVersion, setApiVersion] = useState<string>("取得中...");
  useEffect(() => {
    if (!httpClient) {
      return;
    }
    (async () => {
      const {
        data: { version },
      } = await httpClient.get<VersionType>("/api/version");
      setApiVersion(version);
    })().catch(() => {
      setApiVersion("unknown");
    });
  }, [httpClient]);
  return (
    <SigninedTemplate>
      <Container className="About h-100 d-flex align-items-center">
        <div className="m-auto text-center p-4 box">
          <LogoText />
          <p className="mt-3 mb-0">
            version: {Version} ({apiVersion})
          </p>
          <p className="text-muted">support-athena-ai@dirbato.co.jp</p>
          <p className="mt-5 mb-5">
            Athena AI is a cutting-edge application designed to support project
            management tasks. By leveraging the full potential of AI, it offers
            innovative solutions to streamline your workflow.
          </p>
          <p className="small">
            &copy; 2024 AthenaAI. All rights reserved.
            <br />
            Developed by the PMCoE Project
            <span className="d-none d-sm-inline-block">&nbsp;</span>
            <br className="d-sm-none" />
            Development Team.
          </p>
        </div>
      </Container>
    </SigninedTemplate>
  );
};
