import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useHttpClient } from "../../http-client";
import { Loading } from "../../organisms/Loading";

type UserOwnRespose = {
  userId: string;
};

export const LoggedIn: FC = () => {
  const httpClient = useHttpClient();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!httpClient) {
        return;
      }
      const { data } = await httpClient.get<UserOwnRespose>(
        "/api/v1/users/own"
      );
      console.log(data);
      navigate("/");
    })().catch((err) => {
      console.error(err);
    });
  }, [navigate, httpClient]);

  return <Loading />;
};
