import { useEffect } from "react";

export function useScrollEventListener(listener: () => void) {
  useEffect(() => {
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [listener]);
}
