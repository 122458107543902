import {
  faCheck,
  faCircleArrowDown,
  faCircleQuestion,
  faCog,
  faCopy,
  faStopCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import retry from "async-retry";
import { AxiosError } from "axios";
import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Collapse,
  Container,
  Form,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { FadeInOut } from "../../atoms/FadeInOut";
import { useHttpClient } from "../../http-client";
import { Loading } from "../../organisms/Loading";
import { SigninedTemplate } from "../../templates/SigninedTemplate";
import { useHiddenElem } from "./UseHiddenElem";
import {
  OpenAIModel,
  RiskDetectionSettingModel,
} from "./RiskDetectionSettingModal";

import {
  RiskDetectionResponse,
  RiskDetectionResultErrorResponse,
  RiskDetectionResultResponse,
} from "./api-types";
import { scrollTop, scrollBottom, scrollTo } from "../../modules";
import {
  convertImpactToLabel,
  convertResolutionObjToList,
  convertResolutionSectionToLabel,
  convertSeverityToLabel,
  copyToClipboard,
  loadCurrentPhase,
  loadLastModelId,
  saveCurrentPhase,
  saveLastModelId,
} from "./module";
import { sampleText } from "./sample";

import "./RiskDetection.scss";

/** フォームデータ定義 */
type FormData = {
  /** 選択された現工程 */
  currentPhase: string;

  /** 議事録/進捗報告 */
  projectStatusText: string;
};

/**
 * リスク検出
 * @returns レンダリング
 */
export const RiskDetection: FC = () => {
  const httpClient = useHttpClient();
  const [phases, setPhases] = useState<string[]>([]);
  const [models, setModels] = useState<OpenAIModel[]>([]);

  /** 選択されたGPTモデルID */
  const [selectedModelId, setSelectedModelId] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>();
  const [detectionResult, setDetectionResult] = useState<
    RiskDetectionResultResponse | RiskDetectionResultErrorResponse
  >();
  //ローディングフラグ
  const [loadingFlg, setLoadingFlg] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [copied, setCopied] = useState(false);
  const [requestNo, setRequestNo] = useState<string | undefined>(undefined);
  const [canceled, setCanceled] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);

  const resultElem = useRef<HTMLDivElement>(null);
  const resultTableWrapElem = useRef<HTMLDivElement>(null);

  // リスク検出結果の要素で隠れいている(ViewPort外)Y座標を算出する
  // 要素が画面内に表示されたら(要素のbottomが画面のbottomより上になったら)Y座標としては0以下の値を返す
  const hiddenY = useHiddenElem(resultElem, [detectionResult]);

  const onClickScrollToItem = useCallback((open: boolean) => {
    setTimeout(
      () => {
        const to = () => {
          if (!resultElem.current) {
            window.requestAnimationFrame(to);
            return;
          }
          const toY =
            resultElem.current.getBoundingClientRect().top + window.scrollY;
          const offset = 85;
          scrollTo(toY - offset, 500);
          resultTableWrapElem?.current?.scrollTo({
            left: 0,
            behavior: "smooth",
          });
        };
        window.requestAnimationFrame(to);
      },
      open ? 500 : 0
    );
  }, []);

  useEffect(() => {
    (async () => {
      if (!httpClient) {
        return;
      }
      // リスク検知ページ(本コンポーネント)起動直後に工程一覧およびモデル一覧取得APIからプロジェクトの工程一覧およびモデルの一覧を取得
      const [{ data: phases }, { data: models }] = await Promise.all([
        httpClient.get<string[]>("/api/v1/projects/default/phases"),
        httpClient.get<OpenAIModel[]>(
          "/api/v1/projects/default/risks/detections/models"
        ),
      ]);
      setPhases(phases);
      setModels(models);
    })().catch((err) => {
      console.error(err);
    });
  }, [httpClient, setPhases, setModels]);

  useEffect(() => {
    const currentPhase = loadCurrentPhase();
    if (phases.length !== 0) {
      if (currentPhase && phases.includes(currentPhase)) {
        setValue("currentPhase", currentPhase);
      } else {
        setValue("currentPhase", phases[0]);
      }
    }
  }, [phases, setValue]);

  useEffect(() => {
    const id = loadLastModelId();
    if (models.length !== 0) {
      if (id && models.map(({ id }) => id).includes(id)) {
        setSelectedModelId(id);
      } else {
        setSelectedModelId(models[0].id);
      }
    }
  }, [models, setSelectedModelId]);

  const currentPhase = watch("currentPhase");
  if (currentPhase) {
    saveCurrentPhase(currentPhase);
  }

  useEffect(() => {
    if (selectedModelId) {
      saveLastModelId(selectedModelId);
    }
  }, [selectedModelId]);

  if (!httpClient) {
    return <Loading />;
  }

  // ヘルプを全て閉じる
  const closeAllHelp = () => {
    if (open1) {
      setOpen1(false);
    }
    if (open2) {
      setOpen2(false);
    }
  };

  // サブミット処理
  const onSubmit = handleSubmit(async ({ currentPhase, projectStatusText }) => {
    closeAllHelp();
    setCanceled(false);
    setHasError(false);
    const content = projectStatusText.trim().split("\n");
    setLoadingFlg(true);
    onClickScrollToItem(open1 || open2);
    try {
      const {
        data: { requestNo },
      } = await httpClient.post<RiskDetectionResponse>(
        "/api/v1/projects/default/risks/detections",
        {
          phases,
          currentPhase,
          data: [{ content }],
          modelId: selectedModelId,
        }
      );
      setRequestNo(requestNo);
      await retry(
        async (_bail) => {
          const { data } = await httpClient.get<
            RiskDetectionResultResponse | RiskDetectionResultErrorResponse
          >(`/api/v1/projects/default/risks/detections/${requestNo}`);

          setDetectionResult(data);
          if (["Pending", "Detecting"].includes(data.status)) {
            throw new Error("status is not detected.");
          }
          setLoadingFlg(false);
          if (data.status === "Error") {
            setHasError(true);
            scrollTop();
          }
        },
        {
          retries: 50,
          minTimeout: 1500,
          maxTimeout: 1500,
        }
      );
    } finally {
      setRequestNo(undefined);
    }
  });

  const onClear = () => {
    clearErrors();
    setValue("projectStatusText", "");
    setDetectionResult(undefined);
    setHasError(false);
  };

  const onCancel = async () => {
    try {
      setCanceled(true);
      await httpClient.post<
        RiskDetectionResultResponse | RiskDetectionResultErrorResponse
      >(`/api/v1/projects/default/risks/detections/${requestNo}/cancel`);
    } catch (e) {
      console.warn((e as AxiosError).message);
    }
  };

  return (
    <SigninedTemplate>
      <Container
        className={["RiskDetection", loadingFlg ? "loading" : ""]
          .filter((e) => e)
          .join(" ")}
      >
        {
          /* サーバーエラー発生時のメッセージ表示 */
          hasError && (
            <Alert className="d-flex align-items-center gap-2" variant="danger">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                size="2x"
                className="ms-n1"
              />
              <div>
                リスク検出中にエラーが発生しました。再度実行する場合は
                <Badge className="ms-1 me-1" bg="success">
                  検出
                </Badge>
                を押してください。
              </div>
            </Alert>
          )
        }
        <h4>リスク検出</h4>
        <small>
          議事録といったテキストベースの入力からリスクの一覧を検出します。
        </small>
        <Form onSubmit={onSubmit} noValidate className="mt-4 mb-4">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              入力情報
              <button
                type="button"
                className="icon-btn"
                aria-controls="show-setting"
                disabled={loadingFlg || selectedModelId === undefined}
                onClick={() => {
                  setShowSettingModal(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faCog}
                  className={loadingFlg ? "fa-disabled" : ""}
                />
              </button>
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3" controlId="phase">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <Form.Label className="mb-0">現プロジェクト工程</Form.Label>
                  <button
                    type="button"
                    className="icon-btn"
                    aria-controls="example-collapse-text"
                    onClick={() => {
                      if (!open1) {
                        if (open2) {
                          setOpen2(false);
                        }
                      }
                      setOpen1(!open1);
                    }}
                  >
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </button>
                </div>
                <Collapse in={open1}>
                  <div id="example-collapse-text" className="mb-2">
                    <div className="alert alert-light m-0 p-2">
                      <h6 className="small">
                        ATTENAR'S HINT - アッテナーズヒント
                      </h6>
                      <p className="small mb-0">
                        プロジェクトの現在の工程を指定してね！ココで指定した工程はこの後入力してもらう議事録がどの工程で作成されたものなのかを判断してリスク検出の精度あげるために使うよ！
                        ただ、βテスト版ではシステム企画や要件定義など固定の工程名しか選択できないので、もし選択できない工程とかあったらごめんね（泣）
                      </p>
                    </div>
                  </div>
                </Collapse>
                <Form.Select
                  {...register("currentPhase")}
                  disabled={phases.length === 0 || loadingFlg}
                >
                  {phases.length === 0 && <option>読み込み中...</option>}
                  {phases.map((phase, idx) => (
                    <option key={idx} value={phase}>
                      {phase}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="report">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <Form.Label className="mb-0">議事録</Form.Label>
                  <span className="badge bg-danger">必須</span>
                  <button
                    type="button"
                    className="icon-btn"
                    aria-controls="example-collapse-text"
                    onClick={() => {
                      if (!open2) {
                        if (open1) {
                          setOpen1(false);
                        }
                      }
                      setOpen2(!open2);
                    }}
                  >
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </button>
                </div>
                <Collapse in={open2}>
                  <div id="example-collapse-text" className="mb-2">
                    <div className="alert alert-light m-0 p-2">
                      <h6 className="small">
                        ATTENAR'S HINT - アッテナーズヒント
                      </h6>
                      <p className="small mb-0">
                        リスクの検出したい議事録を入力してね！議事録って言ってるけど、別にテキストデータであればプロジェクトの状態についてやりとりしたメールやチャットのやりとりでもなんでもありだよ！
                        ただ、βテスト版ではあまり長い文字列は扱ってないんだ。。。ごめんね（泣）
                        <br />
                        あと、サンプルは下に載せておくね。
                      </p>
                      <p className="small w-100 mt-2 mb-0">
                        <div className="text-end">
                          <button
                            type="button"
                            className="icon-btn mb-1"
                            onClick={() => {
                              copyToClipboard(sampleText);
                              setCopied(true);
                              setTimeout(() => {
                                setCopied(false);
                              }, 800);
                            }}
                          >
                            {copied ? (
                              <FontAwesomeIcon
                                className="text-success"
                                icon={faCheck}
                              />
                            ) : (
                              <FontAwesomeIcon icon={faCopy} />
                            )}
                          </button>
                        </div>
                        <textarea
                          className="w-100"
                          rows={5}
                          readOnly={true}
                          value={sampleText}
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </Collapse>
                <Form.Control
                  as="textarea"
                  placeholder="テキストを入力してください"
                  className="report"
                  disabled={phases.length === 0 || loadingFlg}
                  {...register("projectStatusText", {
                    required: { value: true, message: "入力してください" },
                    maxLength: {
                      value: 2047,
                      message: "2047文字以下にしてください",
                    },
                  })}
                  isInvalid={!!errors.projectStatusText}
                />
                {errors.projectStatusText?.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.projectStatusText?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-flex flex-row-reverse gap-2">
                <Button
                  type="submit"
                  disabled={phases.length === 0 || loadingFlg}
                  variant="success"
                >
                  検出
                </Button>
                <Button
                  type="button"
                  disabled={phases.length === 0 || loadingFlg}
                  variant="secondary"
                  onClick={onClear}
                >
                  クリア
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Form>
        {!hasError && detectionResult && (
          <div className="result" ref={resultElem}>
            <h5 className="mt-4">リスク検出結果</h5>
            <div className="table-responsive" ref={resultTableWrapElem}>
              <table className="small risk-list table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="cell-w-nl">リスク</th>
                    <th className="cell-w-lg">リスク詳細</th>
                    <th className="cell-w-nl">概要</th>
                    <th className="cell-w-lg">説明</th>
                    <th className="cell-w-sm">発生確率</th>
                    <th className="cell-w-xl">検出理由</th>
                    <th className="cell-w-nl">影響</th>
                    <th className="text-nowrap">影響度</th>
                    <th className="text-nowrap">
                      プロジェクト
                      <br />
                      影響度
                    </th>
                    <th className="text-nowrap">対応区分</th>
                    <th className="cell-w-xl">リスク顕在化前の解決策</th>
                    <th className="cell-w-xl">リスク顕在化時の対応策</th>
                  </tr>
                </thead>
                <tbody>
                  {detectionResult.risks.map(
                    (
                      {
                        name,
                        description,
                        detail,
                        impact,
                        impactRangeOfProject,
                        summary,
                        severity,
                        reasons,
                        influence,
                        resolution,
                      },
                      idx
                    ) => (
                      <Fragment key={idx}>
                        {(() => {
                          const list = convertResolutionObjToList(resolution);
                          return list.map((elem, elemIdx) => (
                            <tr key={`${idx}-${elemIdx}`}>
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>{idx + 1}</td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>{name}</td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>{detail}</td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>{summary}</td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>{description}</td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>
                                  {convertSeverityToLabel(severity)}
                                </td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>
                                  <ul className="list-group list-group-numbered">
                                    {reasons.map((reason, idx) => (
                                      <li
                                        className={[
                                          "list-group-item bg-transparent border-0 ps-0 pe-0 pb-0",
                                          idx === 0 ? "pt-0" : "",
                                        ]
                                          .filter((e) => e)
                                          .join(" ")}
                                        key={idx}
                                      >
                                        {reason}
                                      </li>
                                    ))}
                                  </ul>
                                </td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>{influence}</td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>
                                  {convertImpactToLabel(impact)}
                                </td>
                              )}
                              {elemIdx === 0 && (
                                <td rowSpan={list.length}>
                                  {convertImpactToLabel(impactRangeOfProject)}
                                </td>
                              )}
                              <td>
                                {convertResolutionSectionToLabel(elem.section)}
                              </td>
                              <td>{elem.prevention}</td>
                              <td>{elem.response}</td>
                            </tr>
                          ));
                        })()}
                      </Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
            {loadingFlg ? (
              <LoadingSpinner />
            ) : (
              detectionResult.risks.length === 0 &&
              (canceled ? (
                <small className="ps-2 pe-2">キャンセルされました。</small>
              ) : (
                <small className="ps-2 pe-2">検出されませんでした。</small>
              ))
            )}
          </div>
        )}
        <FadeInOut
          fadeIn={!hasError && !!detectionResult && hiddenY > 10}
          className={`d-flex justify-content-center fixed-bottom fixed-bottom-${
            loadingFlg ? "lg" : "md"
          }`}
        >
          <FontAwesomeIcon
            icon={faCircleArrowDown}
            size="2xl"
            onClick={scrollBottom}
          />
        </FadeInOut>

        <footer className="fixed-bottom bg-secondary d-flex justify-content-center">
          {detectionResult && (
            <Collapse in={loadingFlg}>
              <div id="example-collapse-text3" className="flex-fill">
                <div className="d-flex justify-content-center">
                  <div className="flex-fill d-flex align-items-center">
                    {canceled && (
                      <ProgressBar
                        className="flex-fill ms-2 me-2"
                        variant="secondary"
                        animated
                        now={100}
                        label="キャンセル中..."
                      />
                    )}
                    {!canceled && detectionResult.status === "Pending" && (
                      <ProgressBar
                        className="flex-fill ms-2 me-2"
                        variant="warning"
                        animated
                        now={100}
                        label="検出待ち..."
                      />
                    )}
                    {!canceled && detectionResult.status === "Detecting" && (
                      <ProgressBar
                        className="flex-fill ms-2 me-2"
                        animated
                        now={(() => {
                          if (
                            !detectionResult.progress ||
                            detectionResult.progress.detectedRisks === 0
                          ) {
                            return 5;
                          }
                          if (
                            detectionResult.progress.detectedRisks ===
                            detectionResult.progress.totalRisks
                          ) {
                            return 100;
                          }
                          return Math.floor(
                            (detectionResult.progress.detectedRisks /
                              detectionResult.progress.totalRisks) *
                              95 +
                              5
                          );
                        })()}
                        label={`${
                          detectionResult?.progress
                            ? `${detectionResult?.progress?.detectedRisks} / ${detectionResult?.progress?.totalRisks}`
                            : ""
                        }`}
                      />
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="icon-btn p-1"
                      onClick={onCancel}
                      disabled={canceled}
                    >
                      <FontAwesomeIcon
                        icon={faStopCircle}
                        style={{ fontSize: "1.7em" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Collapse>
          )}
        </footer>
      </Container>
      <RiskDetectionSettingModel
        show={showSettingModal}
        models={models}
        selectedModelId={selectedModelId || ""}
        handleSubmit={({ selectedModelId }) => {
          setSelectedModelId(selectedModelId);
          setShowSettingModal(false);
        }}
        handleClose={() => {
          setShowSettingModal(false);
        }}
      />
    </SigninedTemplate>
  );
};

const LoadingSpinner: FC = () => {
  return (
    <div className="d-flex justify-content-center gap-1 mt-2 mb-2">
      <Spinner animation="grow" size="sm" />
      <Spinner animation="grow" size="sm" />
      <Spinner animation="grow" size="sm" />
    </div>
  );
};
