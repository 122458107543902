import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { Guard, authInit } from "./msal-react-auth";
import { Root } from "./organisms/Root";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { onUpdated } from "./organisms/SWUpdate";
import { About } from "./pages/About";
import { LoggedIn } from "./pages/LoggedIn";
import { Login } from "./pages/Login";
import { RiskDetection } from "./pages/RiskDetection";
import { Tutorial } from "./pages/Tutorial";

import "./index.scss";

(async () => {
  const msalInstance = await authInit();

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root instance={msalInstance} />,
      children: [
        {
          path: "",
          element: (
            <Guard>
              <RiskDetection />
            </Guard>
          ),
        },
        {
          path: "tutorial",
          element: (
            <Guard>
              <Tutorial />
            </Guard>
          ),
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "logged-in",
          element: (
            <Guard>
              <LoggedIn />
            </Guard>
          ),
        },
        {
          path: "about",
          element: (
            <Guard>
              <About />
            </Guard>
          ),
        },
      ],
    },
  ]);
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  // serviceWorkerRegistration.unregister();
  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      if (registration.waiting) {
        onUpdated(registration);
      }
    },
  });

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})().catch((err) => {
  console.error(err);
});
