import { useMsal } from "@azure/msal-react";
import { FC, useEffect } from "react";

import { loginRequest } from "../../msal-react-auth";

export const Login: FC = () => {
  const { instance } = useMsal();

  useEffect(() => {
    (async () => {
      await instance.loginRedirect(loginRequest);
    })().catch((err) => {
      console.error(err);
    });
  }, [instance]);

  return null;
};
