import { FC, useEffect } from "react";
import { Button, Form, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

export type OpenAIModel = { id: string; name: string };

type RiskDetectionSettingModelInputs = {
  selectedModelId: string;
};

export const RiskDetectionSettingModel: FC<{
  show: boolean;
  models: OpenAIModel[];
  selectedModelId: string;
  handleSubmit: (data: RiskDetectionSettingModelInputs) => void;
  handleClose: () => void;
}> = ({ show, models, selectedModelId, handleSubmit, handleClose }) => {
  const {
    register,
    handleSubmit: handleSubmitForm,
    setValue,
  } = useForm<RiskDetectionSettingModelInputs>();

  useEffect(() => {
    if (show) {
      setValue("selectedModelId", selectedModelId);
    }
  }, [selectedModelId, show, setValue]);

  const onSubmit: SubmitHandler<RiskDetectionSettingModelInputs> = (data) => {
    handleSubmit(data);
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmitForm(onSubmit)} noValidate>
        <ModalHeader closeButton>設定</ModalHeader>
        <ModalBody>
          <h4>モデル設定</h4>

          <Form.Group className="mb-3" controlId="model">
            <Form.Label>GPTモデル</Form.Label>
            <Form.Select {...register("selectedModelId")}>
              {models.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </ModalBody>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            キャンセル
          </Button>
          <Button type="submit" variant="success">
            設定
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
