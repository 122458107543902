import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { FC } from "react";
import { Outlet } from "react-router-dom";

import { SWUpdateDialog } from "../SWUpdate";

export const Root: FC<{ instance: PublicClientApplication }> = ({
  instance,
}) => {
  return (
    <MsalProvider instance={instance}>
      <Outlet />
      <SWUpdateDialog />
    </MsalProvider>
  );
};
