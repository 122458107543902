let CompletedTutorial: boolean | undefined = undefined;

export function completedTutorial() {
  if (CompletedTutorial !== undefined) {
    return CompletedTutorial;
  }
  CompletedTutorial = localStorage.getItem("completedTutorial") === "true";
  return CompletedTutorial;
}

export function completeTutorial() {
  CompletedTutorial = true;
  localStorage.setItem("completedTutorial", "true");
}
