import { useMsal } from "@azure/msal-react";
import axios, { AxiosInstance } from "axios";
import { useAuthEnv } from "../msal-react-auth";

const instances: { [key: string]: AxiosInstance } = {};

export function useHttpClient(scopes: string[] = ["read"]) {
  const { instance } = useMsal();
  const env = useAuthEnv();

  if (!env) {
    return undefined;
  }
  if (scopes.join("_") in instances) {
    return instances[scopes.join("_")];
  }

  const inst = axios.create();
  inst.interceptors.request.use(async (config) => {
    const silentRequest = {
      scopes: scopes.map((scope) => `${env.audience}/${scope}`),
    };
    const { accessToken } = await instance.acquireTokenSilent(silentRequest);
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
    return newConfig;
  });
  instances[scopes.join("_")] = inst;
  return inst;
}
