/**
 * スクロールトップ
 */
export function scrollTop(behavior: ScrollBehavior = "auto") {
  window.scrollTo({
    top: 0,
    behavior,
  });
}

/**
 * スクロールボトム
 */
export function scrollBottom() {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: "auto",
  });
}

/**
 * スクロール
 *
 * @param targetY スクロール先のY座標
 * @param duration 遅延 ミリ秒
 */
export function scrollTo(targetY: number, duration: number) {
  const startingY = window.scrollY || 0;
  const diff = targetY - startingY;
  let start: number;

  const step = (timestamp: number) => {
    if (!start) {
      start = timestamp;
    }
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);
    window.scrollTo({
      top: startingY + diff * percent,
      behavior: "auto",
    });

    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
