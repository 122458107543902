import { FC, ReactNode, useEffect } from "react";
import { Header } from "../organisms/Header";
import { scrollTop } from "../modules";

export const SigninedTemplate: FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {
    scrollTop("instant");
  }, []);
  return (
    <>
      <Header />
      {children}
    </>
  );
};
