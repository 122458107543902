import { Impact, Resolution, Severity } from "./api-types";
import { ResolutionSection } from "./types";

// 影響度をラベルに変換
export function convertImpactToLabel(impact: Impact) {
  switch (impact) {
    case "Big":
      return "大";
    case "Medium":
      return "中";
    case "Small":
      return "小";
  }
}

// 発生確率をラベルに変換
export function convertSeverityToLabel(severity: Severity) {
  switch (severity) {
    case "High":
      return "高";
    case "Medium":
      return "中";
    case "Low":
      return "小";
  }
}

// 対応区分をラベルに変換
export function convertResolutionSectionToLabel(section: ResolutionSection) {
  switch (section) {
    case "avoidance":
      return "回避";
    case "mitigation":
      return "軽減";
    case "transference":
      return "転嫁";
    case "acceptance":
      return "受容";
  }
}

// 解決策オブジェクトをリストに変換
export function convertResolutionObjToList(resolution: Resolution) {
  const rtn: {
    section: ResolutionSection;
    prevention?: string;
    response?: string;
  }[] = [];
  if (resolution.avoidance) {
    rtn.push({
      section: "avoidance",
      prevention: resolution.avoidance.prevention,
      response: resolution.avoidance.response,
    });
  }
  if (resolution.mitigation) {
    rtn.push({
      section: "mitigation",
      prevention: resolution.mitigation.prevention,
      response: resolution.mitigation.response,
    });
  }
  if (resolution.transference) {
    rtn.push({
      section: "transference",
      prevention: resolution.transference.prevention,
      response: resolution.transference.response,
    });
  }
  if (resolution.acceptance) {
    rtn.push({
      section: "acceptance",
      prevention: resolution.acceptance.prevention,
      response: resolution.acceptance.response,
    });
  }
  return rtn;
}

let _currentPhase: string | undefined;

export function loadCurrentPhase() {
  if (_currentPhase) {
    return _currentPhase;
  }
  _currentPhase = localStorage.getItem("currentPhase") || undefined;
  return _currentPhase;
}

export function saveCurrentPhase(phase: string) {
  _currentPhase = phase;
  localStorage.setItem("currentPhase", _currentPhase);
}

let _lastModelId: string | undefined;

export function loadLastModelId() {
  if (_lastModelId) {
    return _lastModelId;
  }
  _lastModelId = localStorage.getItem("lastModelId") || undefined;
  return _lastModelId;
}

export function saveLastModelId(id: string) {
  _lastModelId = id;
  localStorage.setItem("lastModelId", _lastModelId);
}

export async function copyToClipboard(text: string) {
  await global.navigator.clipboard.writeText(text);
}




