import { FC, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

import { setNortificationSwUpdate } from "./module";

export const SWUpdateDialog: FC<{}> = () => {
  const [show, setShow] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [registration, setRegistration] = useState<ServiceWorkerRegistration>();

  useEffect(() => {
    setNortificationSwUpdate((registration) => {
      setRegistration(registration);
      setShow(true);
    });
  }, []);

  const handleUpdate = () => {
    registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
    setUpdating(true);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  if (!show) {
    return null;
  }

  return (
    <Modal show={show} size="lg" centered>
      <Modal.Header className="justify-content-center">
        <Modal.Title>バージョンアップ</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        新バージョンがリリースされました🎉
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {updating ? (
          <Button variant="success" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            アップデート
          </Button>
        ) : (
          <Button variant="success" onClick={handleUpdate}>
            アップデート
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
