import { faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { completeTutorial } from "./module";
import { Slide } from "./Slide";
import { TutorialContext } from "./TutorialContext";

import img01 from "./img/img01.jpg";
import img02 from "./img/img02.jpg";
import img03 from "./img/img03.jpg";
import img04 from "./img/img04.jpg";
import img05 from "./img/img05.jpg";

import "./Tutorial.scss";

export const Tutorial: FC = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showSkip, setShowSkip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSkip(true);
    }, 3000);
  }, []);

  const onFinish = () => {
    completeTutorial();
    navigate("/");
  };

  return (
    <TutorialContext.Provider value={{ activeIndex }}>
      <Container className="Tutorial h-100">
        <div className="d-flex h-100 w-100 align-items-center justify-content-center">
          <div className="flex-row w-100 carousel-wrapper position-relative">
            <Carousel
              interval={null}
              controls={false}
              activeIndex={activeIndex}
            >
              <Carousel.Item>
                <Slide
                  targetActiveIndex={0}
                  initialDelay={1500}
                  showButtonDelay={11000}
                  alt="img01"
                  src={img01}
                  captionHeader={
                    <>
                      ベータ版テストに
                      <br />
                      ご参加いただきありがとうございます
                    </>
                  }
                  sequence={[
                    "ゴッホんっっっ、、、",
                    500,
                    "はじめまして！！！",
                    500,
                    "はじめまして Athena AI の大スター",
                    100,
                    "はじめまして Athena AI の大スター。",
                    300,
                    "はじめまして Athena AI の大スター。。",
                    300,
                    "はじめまして Athena AI の大スター。。。",
                    600,
                    "はじめまして Athena AI の大スター。。。あ、間違えた、ちょっとなs",
                    300,
                    "はじめまして Athena AI の愛すべきマスコット兼これから皆様をサポートさせていただきます",
                    300,
                    'はじめまして Athena AI の愛すべきマスコット兼これから皆様をサポートさせていただきます "アッテーナー ゼロ号機" です！',
                  ]}
                  onNext={() => {
                    setActiveIndex(1);
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <Slide
                  targetActiveIndex={1}
                  showButtonDelay={4200}
                  alt="img02"
                  src={img02}
                  captionHeader="数分ほどお時間ください"
                  sequence={[
                    "お忙しい皆さんの時間をいただき大変恐縮ですが、",
                    300,
                    "お忙しい皆さんの時間をいただき大変恐縮ですが、β版で公開する機能について説明させてください。",
                  ]}
                  onNext={() => {
                    setActiveIndex(2);
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <Slide
                  targetActiveIndex={2}
                  alt="img03"
                  src={img03}
                  captionHeader="(β版公開)リスク検出機能"
                  sequence={[
                    "β版ではリスクの検出機能をお試しいただけます。",
                    500,
                    "β版ではリスクの検出機能をお試しいただけます。本機能では議事録といったテキストベースの入力からリスクの一覧を検出できます。",
                  ]}
                  onNext={() => {
                    setActiveIndex(3);
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <Slide
                  targetActiveIndex={3}
                  showButtonDelay={6000}
                  alt="img04"
                  src={img04}
                  captionHeader="(β版公開)制限について"
                  sequence={[
                    "検出できるリスクはスケジュール遅延とリソース不足のみとなります。",
                    500,
                    "検出できるリスクはスケジュール遅延とリソース不足のみとなります。正式リリース版ではさらに検出できるリスクが増えます。",
                    500,
                    "検出できるリスクはスケジュール遅延とリソース不足のみとなります。正式リリース版ではさらに検出できるリスクが増えます。お楽しみに！",
                  ]}
                  onNext={() => {
                    setActiveIndex(4);
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <Slide
                  targetActiveIndex={4}
                  alt="img05"
                  src={img05}
                  captionHeader="さぁ、テスト開始です"
                  sequence={[
                    "心の準備はいいですか？",
                    1000,
                    "リスク検出機能をぜひお試しあれ！",
                  ]}
                  captionBodyClassName="text-center"
                  nextButtonText="開始する"
                  onNext={onFinish}
                />
              </Carousel.Item>
            </Carousel>
            {showSkip && (
              <div className="position-absolute top-0 end-0 animate__animated animate__fadeIn">
                <button
                  className="btn btn fa-beat-fade fa-anime-count-3"
                  onClick={onFinish}
                >
                  skip
                  <FontAwesomeIcon icon={faForward} className="ms-1" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </TutorialContext.Provider>
  );
};
