let _registration: ServiceWorkerRegistration | undefined = undefined;

let _cb: ((registration: ServiceWorkerRegistration) => void) | undefined =
  undefined;

export function onUpdated(registration: ServiceWorkerRegistration) {
  _registration = registration;
  if (_cb) {
    _cb(_registration);
  }
}

export function setNortificationSwUpdate(
  cb: (registration: ServiceWorkerRegistration) => void
) {
  _cb = cb;
  if (_registration) {
    cb(_registration);
  }
}
