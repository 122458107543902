export const sampleText = `
4/1 進捗会議議事録

参加者
　佐藤 一郎
　鈴木 二郎
　山田 三郎

決定事項
　・なし

宿題事項
　・なし

議題
　・要件定義フェーズの費用について
　　→人件費が高すぎてすぐに決済が降りない場合、現行メンバーで予備検討を行うか？（鈴木）
　　→費用捻出のために推進部署に応援をお願いしてはどうか？（佐藤）
　　→開発用のIDEは有料版を利用するか？（鈴木）
　　→利用する可能性があるので費用について検討する（佐藤）

その他
　・なし
`.trim();
